import {
	$dqs,
	$dqsa,
	gsSession,
	imgLoadLimit,
	isMobile,
	setDelay,
	isWebPSupported,
} from "./common/utils";
import v from "./common/variable";
import { initial, importStyle, importScript } from "./common/common";
import u from "./user_need/user_need";
import { requests } from "./common/network";
import { updateLayout } from "./reviews/reviews";
import svg from "./common/svg";
// if (module.hot) {
// 	module.hot.accept();
// }

const execute = async function () {
	if (typeof trustoo_shopify_block_status !== "undefined") {
		if ($dqs("script[src*=vstar-review]") && v.isOverwriteOldScripts) {
			trustoo_shopify_block_status = 2;
			u.executeDelay = 1000;
		}
		if (trustoo_shopify_block_status === 1) {
			return;
		} else if (trustoo_shopify_block_status === 2) {
			trustoo_shopify_block_status = 1;
		}
	}
	let shop_id,
		pageType = "";
	window.Review = {};
	Review.observeCount = 0;

	v.productId = shopifyObj.productId;
	v.env = ENV;
	v.staticBaseUrl = STATIC_BASE_URL;
	v.isMobile = Review.isMobile = isMobile();
	Review.constructTime = TIME;
	v.constructTime = new Date(TIME).getTime() / 1000;
	Review.theme = {};
	if (typeof Shopify !== "undefined") {
		Review.shop = Shopify.shop;
		Object.assign(Review.theme, Shopify.theme);
	}
	if ($dqs("body.warehouse--v1")) {
		Review.theme.name = "warehouse";
	}
	v.baseUrl = Review.baseUrl = BASE_URL;
	v.isWebPSupported = isWebPSupported();
	Review.collTarget = [];
	// 报错信息
	Review.loadedPhoto = [];
	Review.checkGridHighMsg = [];
	Review.checkGridMsg = [];
	if (window.ShopifyAnalytics) {
		shop_id =
			window.ShopifyAnalytics &&
			ShopifyAnalytics.lib &&
			ShopifyAnalytics.lib.config &&
			ShopifyAnalytics.lib.config.Trekkie.defaultAttributes.shopId;
		if (!shop_id) {
			shop_id = shopifyObj.shop_id;
		}
		Review.shop_id = v.shop_id = shop_id;

		if (typeof Shopify !== "undefined" && Shopify.theme) {
			v.theme_store_id = Shopify.theme.theme_store_id;
			v.themeId = Shopify.theme.id;
		}

		pageType =
			window.ShopifyAnalytics && window.ShopifyAnalytics.meta.page.pageType;
	} else {
		const data = document.querySelector("#seal_shop_id");
		if (!data) {
			return;
		}

		if (typeof tt_page_type !== "undefined") {
			pageType = tt_page_type;
		}
		const shop_idData = JSON.parse(
			document.querySelector("#seal_shop_id").textContent
		);
		Review.shop_id = v.shop_id = shop_idData;

		if (pageType === "product") {
			const product = JSON.parse(
				document.querySelector("#tt_product").textContent
			);
			Review.productId = v.productId = product.id;
			v.productName = product.title;
			v.productImages = product.image_url;
		}
	}
	Review.pageType = pageType;
	v.pageType = pageType;
	v.isHomePage = pageType === "index" || pageType === "home";
	v.isCollPage = pageType === "collection";
	v.isProPage = pageType === "product";
	v.isCartPage = pageType === "cart";
	let reqErr, data;
	// 获取店铺数据更新状态
	const gisRes = await requests.getInstallStatus();
	if (gisRes[0] !== null || gisRes[1].code === -1) return;
	// 评分是否有更新
	v.isProRatingUpdate = gisRes[1].product_rating_updating === 1;
	// 设置是否有更新
	v.isSettingUpdate = gisRes[1].shop_setting_updating === 1;
	// 评论是否有更新
	v.isReviewsUpdate = gisRes[1].product_review_updating === 1;
	if (v.isReviewsUpdate) {
		v.revReqParam = new Date().getTime();
	} else {
		v.revReqParam = gisRes[1].product_review_updating;
	}

	gsSession("set", "instagram_is_install", gisRes[1].instagram_is_install);

	if (gisRes[1].review_is_install !== 1) return;
	const setting =
		typeof vstar_review_settings === "undefined"
			? false
			: vstar_review_settings;
	if (setting && !v.isSettingUpdate) {
		data = setting;
	} else {
		[reqErr, data] = await requests.getUserSetting();
		if (reqErr !== null) {
			await setDelay(1000);
			[reqErr, data] = await requests.getUserSetting();
		}
		if (reqErr !== null) {
			await setDelay(3000);
			[reqErr, data] = await requests.getUserSetting();
		}
	}
	v.isActWdoRev = data.sidetab_setting.sidetab_is_enabled === 1 ? true : false;

	u.setPageReview(shop_id);
	if (!v.isProPage) {
		const pyRatingBlock = $dqsa(".pagefly-star-rating-sealapp");
		const ecomRtBlk = $dqsa(".ecom-star-rating-sealapp");
		const ctmRtBlk = $dqsa(".custom-vstar-rating-widget");
		if (pyRatingBlock.length !== 0) {
			const selector = ".pagefly-star-rating-sealapp";
			u.isCollRevWord = true;
			v.hasPyRating = true;
			u.customCollTarSel = selector;
			Review.collTarget = Array.from(pyRatingBlock);
		} else if (ecomRtBlk.length !== 0) {
			u.customCollTarSel = ".ecom-star-rating-sealapp";
			v.hasEcomRt = true;
			Review.collTarget = Array.from(ecomRtBlk);
		}

		if (ctmRtBlk.length !== 0) {
			u.customCollTarSel = ".custom-vstar-rating-widget";
			v.hasPyRating = true;
			Review.collTarget = Array.from(ctmRtBlk);
		}
	}

	// 运行前执行用户需求自定义函数
	if (typeof trustooBeforeExecute !== "undefined") {
		trustooBeforeExecute(v, u);
	}

	let isTarPage =
		v.isProPage ||
		v.isCollPage ||
		v.isHomePage ||
		v.isCartPage ||
		Shopify.designMode ||
		u.isPageReview;

	if (
		isTarPage ||
		v.isActWdoRev ||
		u.otherPageColl ||
		v.hasPyRating ||
		v.hasEcomRt
	) {
		const timestamp = gsSession("get", "vstarTimestamp");
		if (timestamp) {
			v.timestamp = timestamp;
			v.isCssLoaded = true;
		} else {
			v.isFirstExecute = true;
			const timestamp = new Date().getTime();
			gsSession("set", "vstarTimestamp", timestamp);
			v.timestamp = timestamp;
		}

		const detailRev = {
			trustoo_reviews: [],
			trustoo_show_reviews: [],
			trustoo_all_reviews: [],
			trustoo_album_reviews: [],
		};
		gsSession("set", "tt_detail_reviews", JSON.stringify(detailRev));

		const ratingIconType = data.rating_icon_type || 1;
		const badgeIconType = data.verified_icon_type || 1;
		const iconString = gsSession("get", "ttRatingIcons");
		let sessionIcons = {};
		if (iconString) {
			sessionIcons = JSON.parse(iconString);
			v.ratingIconSvg = sessionIcons["star" + ratingIconType];
			v.badgeIconSvg = sessionIcons["badge" + badgeIconType];
		}
		if (ratingIconType === 1) {
			v.ratingIconSvg = svg.star;
		}
		if (badgeIconType === 1) {
			v.badgeIconSvg = svg.verified;
		}
		if (!v.ratingIconSvg || !v.badgeIconSvg) {
			requests.getIcons().then(d => {
				const ratingIconName = getRatingIconName(ratingIconType);
				if (d[0]) {
					v.ratingIconSvg = svg.star;
					v.badgeIconSvg = svg.verified;
				} else {
					const icons = JSON.parse(d[1]);
					v.ratingIconSvg = icons[ratingIconName];
					v.badgeIconSvg = icons["badge" + badgeIconType];
				}
				$dqsa(".vstar-star .pending").forEach(it => {
					it.innerHTML = v.ratingIconSvg;
					it.classList.remove("pending");
				});
				$dqsa("#vstar-reviews .user-verified.pending").forEach(it => {
					it.insertAdjacentHTML("afterbegin", v.badgeIconSvg);
					it.classList.remove("pending");
				});
				sessionIcons["star" + ratingIconType] = v.ratingIconSvg;
				sessionIcons["badge" + badgeIconType] = v.badgeIconSvg;
				gsSession("set", "ttRatingIcons", JSON.stringify(sessionIcons));
			});
		}

		let globalStyle = document.createElement("style");
		globalStyle.id = "trustoo-css";
		document.head.appendChild(globalStyle);
		v.globalStyle = globalStyle;

		u.setUserNeed();
		Object.assign(v, u);
		const start = async function () {
			if (v.isProPage) {
				let productId = shopifyObj.productId,
					productImages = shopifyObj.productImage[0],
					productName = shopifyObj.productName;
				Review.productId = productId;
				Object.assign(v, { productId, productImages, productName });
				const index = location.href.indexOf("/products/");
				if (index !== -1) {
					v.productHandle = location.href.substring(index + 10);
				}
			}
			imgLoadLimit(
				[
					"//cdn.vstar.app/static/images/default.png",
					"//cdn.vstar.app/static/images/video_default.png",
					`//${v.staticBaseUrl}/static/images/sprites/trustoo_sprites.png?${v.constructTime}`,
					"//rtestweb.sealapps.com/static/images/player.png",
				],
				3
			);
			initial(data);
			importStyle();
			importScript();
			if (v.isProPage || v.isHomePage || u.isPageReview) {
				window.addEventListener("resize", function () {
					if (!v.isMobile && v.isGrid) {
						updateLayout();
					}
				});
			}
		};
		if (u.executeDelay) {
			await setDelay(u.executeDelay);
		}
		start();
	}
	function getRatingIconName(type) {
		const codes = [
			"star",
			"rounded",
			"spikes",
			"surrounded",
			"fire",
			"heart",
			"diamond",
			"crown",
			"trophy",
			"shirt",
			"skirt",
			"bag",
			"tie",
			"cup",
			"leaf",
			"seedling",
			"footprint",
			"chef",
		];
		return codes[type - 1];
	}
};
setTimeout(() => execute(), 100);
