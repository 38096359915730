import { qaRequests } from "../common/network";
import svg from "../common/svg";
import { $dqs, gsLocal } from "../common/utils";
import v from "../common/variable";
import { Pager, sgPage } from "../reviews/review-pager";
import { checkHelpful, dateFormat, getTimeAgo } from "../reviews/reviews-body";

let isFirstPage = false,
	isShowHelpful = false,
	pageSize = 0,
	dateType = 0;

// 嵌入QA
async function insertQA() {
	// 添加QA设置样式
	let s = null;
	if (typeof vstar_qa_settings !== "undefined" && !v.isSettingUpdate) {
		s = vstar_qa_settings;
	} else {
		const res = await qaRequests.getSetting();
		res[0] === null && (s = res[1]);
	}

	// 请求错误，则退出，没有则继续
	if (s) {
		// 没有激活则退出
		if (s.is_active !== 1) {
			return;
		}
		sgPage(3, 1);
		pageSize = s.page_size;
		isShowHelpful = s.is_show_helpful;
		// 嵌入设置样式
		let styleTxt = [];
		const colors = s.color.split(",");
		const colorName = [
			"title-color",
			"title-line-color",
			"line-color",
			"question-color",
			"answer-color",
			"reply-bg-color",
		];

		colorName.forEach((it, inx) => {
			styleTxt.push(`--${it}:${colors[inx]};`);
		});

		v.globalStyle.textContent += `
		.tt-tabs-list{
			${styleTxt.slice(0, 2).join("")}
		}
		.tt-qa-list{
			${styleTxt.slice(2).join("")}
				--author-font-size:${s.author_font_size}px;
				--content-font-size:${s.content_font_size}px;
		}
		`;
		dateType = s.review_date_format;
		setQAList();
	}
}

async function setQAList() {
	if (!isFirstPage) {
		$dqs("#vstar-reviews #reviews-body,#vstar-noreviews").insertAdjacentHTML(
			"beforeend",
			`<div class="tt-qa-list"></div>`
		);
	}

	const res = await qaRequests.getList({ limit: pageSize });
	if (res[1]) {
		if (!isFirstPage) {
			isFirstPage = true;
			const reviewsDom = v.reviewsDom;

			setQAEvent();
			const pageCount = res[1].page.total_page;
			reviewsDom.setAttribute("qa-page-count", pageCount);
			if (pageCount > 1) {
				new Pager(
					$dqs("#reviews-footer", reviewsDom),
					"beforeend",
					5,
					pageCount,
					setQAList,
					"qa"
				);
			}
		}
	}
	const list = res[1].list;
	let html = "";
	if (list.length) {
		list.forEach(it => {
			// 时间格式
			// let questionDate = dateFormat();
			// dateFormat;
			let answerHtml = "";
			if (it.qa_reply_data) {
				let helpfulHtml = "";
				if (isShowHelpful === 1) {
					const LocalKey = "tt_qa_" + v.shop_id;
					let records = gsLocal("get", LocalKey);
					let helpfulCls = "",
						unhelpfulCls = "";
					if (records) {
						records = JSON.parse(records);
						const active = records.find(record => record.id == it.id);
						if (active) {
							if (active.likeType === 1) {
								helpfulCls = "active";
							} else if (active.likeType === 2) {
								unhelpfulCls = "active";
							}
						}
					}
					helpfulHtml = `<div class="review-helpful"><span>${
						v.lang.question_and_answer.helpful
					}</span>
					${svg.helpful(helpfulCls)}<span class="tt-helpful-count">${
						it.qa_reply_data.likes_qa
					}</span>
					${svg.unhelpful(unhelpfulCls)}<span class="tt-unhelpful-count">${
						it.qa_reply_data.dislike_qa
					}</span></div>`;
				}

				answerHtml = `<div class="tt-reviews-answer" >
				<div class="first-row">
					<span class="tt-answerer" >${it.qa_reply_data.answerer} </span>
					<span class="tt-answer-date">${getDateString(
						dateType,
						it.qa_reply_data.create_time
					)}</span>
				</div>
				<div class="second-row" > ${it.qa_reply_data.answer} </div>
				<div class="tt-qa-helpful" style="text-align: right;">
					${helpfulHtml}
				</div>
			</div>`;
			}

			html += `
				<div class="tt-qa" qa-id="${it.id}">
					<div class="tt-review-question" >
						<div class="first-row">
							<span class="tt-questioner" >${it.questioner}</span>
							<span class="tt-question-date">${getDateString(dateType, it.create_time)}</span>
						</div>
					<div class="second-row" > ${it.question} </div>
					</div>
					${answerHtml}
				</div>
				`;
		});
	} else {
		html = `<div style="text-align:center">${svg.qa(
			v.userSetting.font_color
		)}</div><div style="margin-top:4px;text-align:center;font-size:20px">${
			v.lang.no_questions
		}</div>`;
	}

	$dqs(".tt-qa-list", v.reviewsDom).innerHTML = html;
}

function setQAEvent() {
	$dqs(".tt-qa-list", v.reviewsDom).addEventListener("click", function (e) {
		let target = e.target;
		if (target.tagName === "path") {
			target = target.closest("svg");
		}
		let tarCls = target.classList;
		if (
			// 赞踩点击
			tarCls.contains("tt-helpful") ||
			tarCls.contains("tt-unhelpful")
		) {
			checkHelpful(target, tarCls);
		}
	});
}

function getDateString(type, timeString) {
	const timeAgoType = 5;
	if (type === timeAgoType) {
		return getTimeAgo(timeString);
	} else {
		return dateFormat(timeString, type);
	}
}

export { insertQA };
