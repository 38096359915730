export class CartWatcher {
	init() {
		return this.emitCartChanges().then(() => {
			this.observeCartChanges();
		});
	}

	async fetchCart() {
		const response = await fetch("/cart.js");
		return response.json();
	}

	storeCartToken(token) {
		try {
			sessionStorage.setItem("ttCartToken", token);
		} catch (error) {}
	}

	storedCartToken() {
		return sessionStorage.getItem("ttCartToken") || "";
	}

	async emitCartChanges() {
		const newCart = await this.fetchCart();
		const event = new CustomEvent("cart_changed", {
			detail: { token: newCart.token },
		});
		window.dispatchEvent(event);
		this.storeCartToken(newCart.token);
	}

	observeCartChanges() {
		const cartObserver = new PerformanceObserver(list => {
			list.getEntries().forEach(entry => {
				const isValidRequestType = ["xmlhttprequest", "fetch"].includes(
					entry.initiatorType
				);
				const isCartChangeRequest = /\/cart\//.test(entry.name);
				if (isValidRequestType && isCartChangeRequest) {
					this.emitCartChanges();
				}
			});
		});
		cartObserver.observe({ entryTypes: ["resource"] });
	}
}
