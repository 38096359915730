import svg from "../common/svg";
import v from "../common/variable";

// 分页器
function Pager(
	dom,
	position = "beforeend",
	pagerCount = 5,
	pageCount,
	callback,
	type = ""
) {
	this.pageCount = pageCount;
	this.callback = callback;
	this.activeIndex = 0;
	this.middleIndex = parseInt(pagerCount / 2);
	const { userSetting, isWdoRevOpen } = v;
	const reviewsDom = isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const prePageIcon = svg.prePage(userSetting.font_color);
	const nextPageIcon = svg.nextPage(userSetting.font_color);
	const isRtl = dom.parentNode.classList.contains("trustoo-rtl");
	var pagerStr = `<ul class="page-control" type="${type}">
            <li style="display:none" class="page-control-button pre-page">
              ${isRtl ? nextPageIcon : prePageIcon}
            </li>`;
	for (var i = 1; i <= pagerCount; i++) {
		pagerStr += `<li class="page-control-item ${
			i === 1 ? "active" : ""
		} " index="${i - 1}" >${i}</li>`;
	}

	pagerStr += `<li class="page-control-button next-page">
              ${isRtl ? prePageIcon : nextPageIcon}
            </li>
            </ul>`;
	dom.insertAdjacentHTML(position, pagerStr);
	this.el = reviewsDom.querySelector(`.page-control[type="${type}"]`);
	this.pager = this.el.querySelectorAll(`.page-control-item`);
	if (pageCount < 5 && pageCount !== 1) {
		for (var i = pageCount; i < 5; i++) {
			this.pager[i].style.display = "none";
		}
	}
	setTimeout(() => {
		if (pageCount === 1) {
			this.togglePageButton(2);
		}
	}, 0);

	this.pager.forEach(item => {
		item.onclick = () => {
			this.activeIndex = item.getAttribute("index");
			let page = parseInt(item.textContent);
			this.pageClick(callback, page);
		};
	});
	//按钮点击事件
	this.pageClick = function (callback, page) {
		let prePage = this.el.querySelector(
			`.page-control-item.active`
		).textContent;
		const curPageCount = this.pageCount;
		sgPage(3, page);
		if (callback) {
			callback();
		} else {
			this.activeIndex = 0;
		}
		if (prePage == 1 && page != 1) {
			this.togglePageButton(1);
		} else if (prePage != 1 && page == 1) {
			this.togglePageButton(1);
		}
		if (prePage != curPageCount && page == curPageCount) {
			this.togglePageButton(2);
		} else if (prePage == curPageCount && page != curPageCount) {
			this.togglePageButton(2);
		}
		this.setPageNum();
	};

	var prePageButton = this.el.querySelector(".pre-page");
	var nextPageButton = this.el.querySelector(".next-page");

	//按钮状态切换
	this.togglePageButton = function (type) {
		if (type == 1) {
			if (prePageButton.style.display === "none") {
				prePageButton.style.display = "flex";
			} else {
				prePageButton.style.display = "none";
			}
		} else if (type == 2) {
			if (nextPageButton.style.display === "none") {
				nextPageButton.style.display = "flex";
			} else {
				nextPageButton.style.display = "none";
			}
		}
	};

	//Mark 判断页码状态
	this.setPageNum = function () {
		let page = sgPage();
		const curPageCount = this.pageCount;
		if (
			(this.activeIndex > this.middleIndex &&
				this.pager[pagerCount - 1].innerHTML <= curPageCount) ||
			(this.activeIndex < this.middleIndex && this.pager[0].innerHTML > 1)
		) {
			if (
				page + this.middleIndex > curPageCount ||
				page - this.middleIndex <= 0
			) {
				this.el
					.querySelector(".page-control-item.active")
					.classList.remove("active");
			} else if (this.activeIndex != this.middleIndex) {
				this.el
					.querySelector(".page-control-item.active")
					.classList.remove("active");
				this.activeIndex = this.middleIndex;
				this.pager[this.middleIndex].classList.add("active");
			}
			this.changePageNum();
		} else {
			this.el
				.querySelector(".page-control-item.active")
				.classList.remove("active");
			this.pager[this.activeIndex].classList.add("active");
		}
	};

	//下一页
	this.getNextPageReviews = function () {
		sgPage(1);
		const curPageCount = this.pageCount;
		let page = sgPage();
		this.activeIndex++;
		if (page == 2) {
			this.togglePageButton(1);
		}
		if (page == curPageCount) {
			this.togglePageButton(2);
		}
		if (callback) {
			callback();
		}
		//getReviewBody()

		this.setPageNum(2);
	};
	//上一页
	this.getPrePageReviews = function () {
		const curPageCount = this.pageCount;
		this.activeIndex--;
		sgPage(2);
		let page = sgPage();
		if (page == 1) {
			this.togglePageButton(1);
		}
		if (page == curPageCount - 1) {
			this.togglePageButton(2);
		}

		if (callback) {
			callback();
		}
		// getReviewBody()

		this.setPageNum(1);
	};

	nextPageButton.onclick = () => this.getNextPageReviews();
	prePageButton.onclick = () => this.getPrePageReviews();

	//Mark 换页码
	this.changePageNum = function () {
		const curPageCount = this.pageCount;
		let page = sgPage();
		var noPageFlag = false;
		var startNum = page - this.middleIndex;
		var endNum = page + this.middleIndex;
		if (page + this.middleIndex > curPageCount) {
			noPageFlag = true;
			startNum = curPageCount - 2 * this.middleIndex;
			if (pagerCount % 2 == 0) {
				startNum++;
			}
			endNum = curPageCount;
		} else {
			if (pagerCount % 2 == 0) {
				endNum--;
			}
		}

		if (startNum <= 0) {
			noPageFlag = true;
			startNum = 1;
			endNum = startNum + 2 * this.middleIndex;
		}

		var index = 0;
		for (var i = startNum; i <= endNum; i++) {
			if (i === page && noPageFlag) {
				this.pager[index].classList.add("active");
				this.activeIndex = index;
			}
			this.pager[index].innerHTML = i;
			index++;
		}
	};
}
//获取、设置页数
function sgPage(type, num) {
	let reviewsDom = v.isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const key = v.showType === 1 ? "reviews-page-no" : "qa-page-no";
	let page = parseInt(reviewsDom.getAttribute(key));
	if (arguments.length === 1) {
		if (type === 1) {
			page++;
		} else if (type === 2) {
			page--;
		}
		reviewsDom.setAttribute(key, page);
	} else if (type === 3 && arguments.length === 2) {
		reviewsDom.setAttribute(key, num);
	}
	return page;
}

export { Pager, sgPage };
